.bv-profit-page {
    width: 100%;
    display: flex;
    flex-direction: row;

    .kr-manager-filter {
        display: flex;
        justify-content: space-between;
        padding: 15px;
        padding-bottom: 0px;

        .btn {
            font-weight: 600;
            color: #fff;
        }
    }

    div.kr-admin-table {
        box-sizing: border-box;
        width: 100%;
        padding: 15px;
        overflow: hidden;
        color: #000;

        table {
            box-sizing: border-box;
            width: 100%;
            max-width: 100%;
            border-collapse: collapse;
            background: #fff;
            box-shadow: 0px 2px 5px 0px #00000073;

            thead {
                background: #e9ecf2;
                font-weight: 600;

                td {
                    padding: 10px;
                }
            }

            tbody {
                tr {
                    border-bottom: 1px solid #f2f2f2;

                    td {
                        padding: 9px 10px;
                    }
                }
            }
        }
    }

    .mobile-table {
        display: none;
    }

    @media (max-width: 992px) {
        background-color: #0C153B;
        min-height: 100%;
        flex-direction: column;

        .kr-leftside {
            display: none;
        }

        .kr-manager-nav {
            display: none;
        }

        .kr-manager-filter {
            display: none;
        }

        div.kr-admin-table {
            display: none;
        }

        .mobile-table {
            display: block;

            .element-heading {
                position: relative;
                z-index: 1;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
            }

            .card {
                background-color: #242644;

                .table {
                    color: rgba(255, 255, 255, 0.7);
                    border-color: rgba(255, 255, 255, 0.2);
                }

                .form-group {
                    line-height: 1;
                    margin-bottom: 1rem;

                    .form-control {
                        color: #ffffff;
                        background-color: #242644;
                        border-color: rgba(255, 255, 255, 0.1);
                        transition-duration: 500ms;
                        border-style: solid;
                        padding: 0.5rem 0.75rem;
                        height: 40px;
                        font-size: 14px;
                        border-width: 2px;
                    }
                }
            }
        }
    }
}