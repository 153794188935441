.bv-user-layout {
    height: 100vh;
    width: 100%;
    position: relative;

    & > header.desktop {
        display: block;
        z-index: 990;
        position: absolute;
        width: 100%;

        & > div {
            z-index: 1;
            display: flex;
            position: relative;
            height: 70px;
            background: #181f2c;
            box-shadow: 0px 0px 5px 0px #00000073;
            align-items: stretch;
            justify-content: space-between;

            & > div {
                display: flex;
                flex-direction: row;
                min-width: 123px;
                align-items: center;

                div.kr-logo {
                    display: flex;
                    min-width: 194px;
                    padding: 0px 15px;
                    align-items: center;
                    cursor: pointer;

                    img {
                        max-width: 191px;
                        max-height: 70px;
                    }
                }

                div.kr-addgraph-dashboard {
                    display: flex;
                    box-sizing: border-box;
                    position: relative;
                    width: 48px;
                    min-width: 48px;
                    height: 48px;
                    min-height: 48px;
                    margin-left: 12px;
                    color: #fff;
                    border: 2px solid #252c3b;
                    border-radius: 3px;
                    align-items: center;
                    font-size: 12px;
                    justify-content: center;
                    cursor: pointer;

                    a > img {
                        width: 30px;
                        height: 30px;
                        fill: #656c7d;
                    }
                }

                div.kr-heeader-btn {
                    margin-right: 15px;
                    
                    .btn {
                        color: #fff;
                        font-weight: 500;
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 8px 24px;
                        cursor: pointer;
                        transition: all 0.3s ease;

                        &:hover {
                            background-color: #29c359;
                        }
                    }

                    .btn.demo {
                        background-color: #C13921;
                    }
                }

                div.kr-wallet-top {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    height: 70px;
                    position: relative;
                    margin-left: 11px;

                    & > div {
                        display: flex;
                        align-items: center;
                        cursor: pointer;

                        & > div {
                            text-transform: uppercase;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-end;
                            font-weight: 500;
                            margin-right: 10px;

                            span {

                                &:first-child {
                                    font-size: 12px;
                                }

                                &.kr-wallet-top-ammount {
                                    font-size: 23px;
                                }
                            }

                            label {
                                font-size: 9px;
                                opacity: 0.4;
                                margin-top: 2px;
                            }
                        }

                        svg {
                            width: 16px;
                            height: 16px;
                            fill: #29c368;
                        }
                    }

                    div.kr-wallet-top-real {
                        transition: all 0.3s ease-in;
                        color: #29c359;

                        &:hover {
                            opacity: .8;
                        }
                    }

                    div.kr-wallet-top-demo {
                        transition: all 0.3s ease-in;
                        color: #C13921;

                        &:hover {
                            opacity: .8;
                        }
                    }

                    & > section {
                        display: flex;
                        height: auto;
                        position: absolute;
                        top: 70px;
                        right: 0px;
                        width: 678px;
                        background: #181f2c;
                        border-top: 1px solid #262c3b;
                        z-index: 999999999;
                        align-items: stretch;
                        word-break: break-all;
                        box-shadow: 0px 0px 5px 0px #0000001a;

                        & > div {
                            h3 {
                                margin: 0;
                                padding: 0;
                                font-size: 12px;
                                text-transform: uppercase;
                                margin-bottom: 18px;
                            }
                        }

                        div.kr-wallet-top-resum {
                            width: 251px;
                            background: #262c3b;
                            padding: 23px;
                            color: #f4f6f9;

                            ul {
                                display: flex;
                                flex-direction: column;
                                font-size: 13px;
                                font-weight: bold;
                                margin: 0;
                                padding: 0;

                                li {
                                    display: flex;
                                    align-items: flex-end;
                                    margin: 0;
                                    padding: 0;
                                    list-style: none;
                                    margin-bottom: 8px;

                                    & > div {
                                        height: 1px;
                                        flex: 1;
                                        background: rgba(255, 255, 255, 0.05);
                                        margin: 0px 14px;
                                        margin-bottom: 5px;
                                    }
                                }
                            }

                            & > div {
                                text-align: center;
                                width: 100%;
                                margin-top: 10px;
                                text-transform: uppercase;
                                font-size: 12px;
                                font-weight: 500;
                                opacity: 0.7;
                                cursor: pointer;
                                transition: opacity 0.15s linear;

                                &:hover {
                                    opacity: 1;
                                }

                                .btn {
                                    font-weight: bold;
                                    line-height: 27px;
                                    color: #fff;
                                    width: 100%;
                                    &:hover {
                                        background-color: #29c359;
                                    }
                                }
                            }
                        }

                        div.kr-wallet-top-change {
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                            padding: 23px;
                            color: #f4f6f9;

                            .btn-grey {
                                color: #fff;
                                border: none;
                                border-radius: 3px;
                                background: #363c4f;
                                outline: none;
                                text-decoration: none;
                                align-items: center;
                                display: flex;
                                justify-content: center;
                                cursor: pointer;

                                &:hover {
                                    background: #3f4559;
                                }
                            }

                            h3 {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                            }

                            ul {
                                margin: 0;
                                padding: 0;
                                width: 100%;

                                li {
                                    width: 100%;
                                    box-sizing: border-box;
                                    padding: 10px 0px;
                                    padding-right: 10px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
                                    cursor: pointer;

                                    & > div {
                                        display: flex;
                                        align-items: center;
                                        color: #ff7700;

                                        svg {
                                            width: 33px;
                                            height: 33px;
                                            transform: scale(0.5);
                                            fill: #ff7700;
                                        }

                                        & > div {
                                            display: flex;
                                            flex-direction: column;
                                            text-transform: uppercase;
                                            font-weight: bold;
                                            font-size: 13px;

                                            span {
                                                margin-bottom: 3px;
                                            }
                                        }
                                    }

                                    & > section {
                                        display: flex;
                                        align-items: center;

                                        button {
                                            margin-left: 10px;
                                        }
                                    }
                                }

                                li.kr-wallet-top-change-real {
                                    & > div {
                                        color: #26a054;
                                    }
                                    svg {
                                        fill: #26a054;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    & > header.mobile {
        display: none;
    }

    section.kr-page-content {
        display: flex;
        box-sizing: border-box;
        height: calc(100% - 37px);
        max-height: 100%;
        padding-top: 70px;
        align-items: flex-start;
    }

    nav.kr-leftnav {
        display: block;
        height: 100%;
        background: #131722;
        width: 70px;

        & > ul {
            display: flex;
            flex-direction: column;
            height: fit-content;
            padding: 0;
            margin-top: 32px;

            & > li {
                margin: 0;
                padding: 0;
                margin-bottom: 32px;
                display: flex;
                width: 70px;
                max-height: 70px;
                border-bottom: 1px solid #232631;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                cursor: pointer;
                flex: 1;
                position: relative;

                & > a {
                    color: white;
                    text-align: center;
                    text-transform: uppercase;
                    text-decoration: none;
                    animation: fadeInUp 0.5s forwards;

                    & > .icon {
                        display: block;
                        margin: 0 auto;
                        font-size: 28px;
                        // color: #0DC635;
                        transition: color 0.3s ease-in;

                        &:hover {
                            // color: #1ce748;
                        }
                    }

                    & > .icon.logout {
                        color: red;

                        &:hover {
                            color: red;
                        }
                    }

                    & > span {
                        color: #fff;
                        text-align: center;
                        text-transform: uppercase;
                        font-size: 10px;
                        text-decoration: none;
                        font-weight: 100;
                    }
                }
            }
        }
    }

    a.gflag {
        vertical-align: middle;
        font-size: 16px;
        padding: 1px 0;
        background-repeat: no-repeat;
        background-image: url(https://gtranslate.net/flags/16.png);
    }

    a.gflag img {
        border: 0;
    }

    .footer-nav-area {
        display: none;
    }

    @media (max-width: 992px) {
        display: flex;
        flex-direction: column;
        overflow: hidden;

        & > header.desktop {
            display: none;
        }

        & > header.mobile {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #242644;
            width: 100%;
            height: 50px;
            // position: fixed;
            // top: 0;
            // left: 0;
            // right: 0;
            z-index: 1000;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);

            .header-content {
                width: 100%;
                height: 50px;

                .logo-wrapper {
                    img {
                        height: 26px;
                    }
                }

                .navbar-toggler {
                    border-color: rgba(255, 255, 255, 0.15);
                    border: 2px solid #ebebeb;
                    padding: 5px;
                    border-radius: 4px;
                    position: relative;
                    z-index: 1;
                    border-color: rgba(255, 255, 255, 0.15);
                    cursor: pointer;

                    span {
                        background-color: #ffffff;
                        width: 18px;
                        margin-bottom: 4px;
                        height: 2px;
                    }
                }
            }
        }

        section.kr-page-content {
            flex-direction: column;
            padding-top: unset;
            // padding-bottom: 62px;
            height: unset;
            max-height: unset;
            flex: 1;
            overflow: hidden;
            overflow-y: auto;
        }

        nav.kr-leftnav {
            display: none;
        }

        .footer-nav-area {
            display: block;
            background-color: #242644;
            width: 100%;
            height: 62px;
            z-index: 1000;
            border-top: 1px solid rgba(255, 255, 255, 0.1);
            transition-duration: 500ms;

            .footer-nav {
                height: 62px;

                ul {
                    margin: 0;
                    padding: 0;

                    li {
                        position: relative;
                        flex: 1 1 0;
                        list-style: none;
                        text-decoration: none;
                        margin: 0;
                        padding: 0;

                        a {
                            position: relative;
                            display: block;
                            font-size: 10px;
                            text-align: center;
                            font-weight: 600;
                            text-transform: uppercase;
                            line-height: 1;
                            color: rgba(255, 255, 255, 0.5);
                            transition: all 500ms ease;
                            text-decoration: none;
                            outline: 0 solid transparent;

                            span {
                                color: #8480ae;
                                display: block;
                                margin-top: 0.25rem;
                            }

                            &:hover {
                                color: #fff;

                                span {
                                    color: #f1b10f;
                                }
                            }
                        }
                    }

                    li.active {
                        a {
                            color: #fff;

                            span {
                                color: #f1b10f;
                            }
                        }
                    }
                }
            }
        }
    }
}

.mobile-menu-nav {
    display: none;
}

@media (max-width: 992px) {
    .mobile-menu-nav {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        z-index: 9999;
        background-color: rgba($color: #0134d4, $alpha: .5);

        &-enter {
            opacity: 0;

            &-active {
                animation: fadeIn 0.5s forwards;
            }
        }

        &-exit {
            opacity: 1;
            
            &-active {
                animation: fadeOut 0.3s forwards;
            }
        }

        &__sidebar {
            color: #8480ae;
            height: 100%;
            background-color: #242644;
            overflow: hidden;
            overflow-y: auto;
            width: 285px;
            animation: slideInLeft 0.5s forwards;
            position: relative;

            .go-back-btn {
                transition-duration: 300ms;
                position: absolute;
                width: 2rem;
                height: 2rem;
                background-color: transparent;
                top: 16px;
                left: 16px;
                border-radius: 6px;
                color: #ffffff;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 9999;
                cursor: pointer;
            }
        
            &-exit {
                &-active {
                    animation: slideOutLeft 0.5s forwards;
                }
            }

            .sidenav-profile {
                background-color: #000000;
                position: relative;
                z-index: 1;
                text-align: center;
                margin-top: 0;
                padding-top: 3rem;
                padding-bottom: 3rem;

                .user-info {
                    div.btn {
                        margin: 0 auto !important;
                    }
                }

                .user-profile {
                    position: relative;
                    z-index: 1;
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    margin: 0 auto;
                    margin-bottom: 0.5rem;
                    overflow: hidden;
                    border: 0.5px solid rgba($color: #ccc, $alpha: .2);

                    img {
                        height: 100%;
                        border-radius: 50%;
                        width: 100%;
                    }
                }
            }

            .sidenav-nav {
                margin: 1.5rem 0;
                position: relative;
                z-index: 1;

                li {
                    list-style: none;
                    transition-duration: 500ms;
                    position: relative;

                    &:hover {
                        background-color: rgba(255, 255, 255, 0.1);
                    }

                    a {
                        align-items: center;
                        color: #8480ae;
                        padding-top: 0.75rem;
                        padding-bottom: 0.75rem;
                        padding-right: 1.25rem;
                        padding-left: 1.25rem;
                        font-size: 14px;
                        font-weight: 500;
                        display: flex;
                        transition-duration: 500ms;
                        text-decoration: none;
                        outline: 0 solid transparent;

                        &:hover {
                            color: #f1b10f;
                        }

                        svg {
                            margin-right: 1rem;
                        }
                    }

                    .dropdown-trigger-btn {
                        position: absolute;
                        top: 0.5rem;
                        right: 1.25rem;
                        width: 30px;
                        background-color: transparent;
                        height: 30px;
                        text-align: center;
                        border-radius: 4px;
                        cursor: pointer;
                        color: #f1b10f;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        transition: all .5s ease;
                        // transform: rotate(90deg);
                    }

                    .dropdown-trigger-btn.active {
                        transform: rotate(-90deg);
                    }
                }
            }
        }
    }
}