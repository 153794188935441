.bv-edit-profile-page {
    width: 100%;

    .btn {
        color: #fff;
        transition: opacity 0.5s ease-in;

        &:hover {
            color: #fff;
        }
        
        &:disabled {
            opacity: 0.7;
        }
    }

    .kr-manager-filter {
        display: flex;
        justify-content: space-between;
        padding: 15px;
        padding-bottom: 0px;

        .btn {
            font-weight: 600;
            color: #fff;
        }
    }

    .user-data-card,
    .user-info-card {
        display: none;
    }

    .kr-admin-table {
        box-sizing: border-box;
        width: 100%;
        padding: 15px;
        overflow: hidden;

        select {
            padding: 10px 12px;
            border: 1px solid #e1e3e6;
            border-radius: 2px;
            font-family: 'Roboto', sans-serif;
            font-size: 15px;
        }

        .kr-admin-line {
            display: flex;
            box-sizing: border-box;
            width: 100%;
            padding: 15px;
            padding-bottom: 0px;
            align-items: flex-start;

            & > div {
                box-sizing: border-box;
                margin-right: 7px;
                margin-left: 7px;
                padding: 20px;
                background: #fff;
                box-shadow: 0px 2px 5px 0px #00000073;
                flex: 1;

                &:first-child {
                    margin-left: 0;
                }
            }

            & > div.kr-admin-field {
                display: flex;
                align-items: flex-start;

                .btn {
                    display: flex;
                    min-width: 107px;
                    width: fit-content;
                    height: 39px;
                    color: #fff;
                    border: none;
                    border-radius: 3px;
                    background: #363c4f;
                    outline: none;
                    text-decoration: none;
                    align-items: center;
                    font-size: 15px;
                    justify-content: center;
                    cursor: pointer;

                    &:hover {
                        background: #3f4559;
                    }
                }

                & > div {

                    &:first-child {
                        width: 250px;
                        min-width: 250px;
                        font-weight: 700;
                    }

                    &:last-child {
                        flex: 1;
                    }

                    & > input[type="text"] {
                        width: 100%;
                        padding: 10px 12px;
                        border: 1px solid #e1e3e6;
                        border-radius: 2px;
                        font-family: 'Roboto', sans-serif;
                        font-size: 15px;
                        resize: none;
                    }
                }
            }
        }

        .kr-admin-line.kr-admin-line-cls {
            align-items: stretch;
            flex-direction: column;

            & > div {
                margin: 0;
                border-bottom: 1px solid #e9e9e9;
                box-shadow: none;
            }
        }

        div.kr-admin-action {
            display: flex;
            padding: 15px 15px 0px 15px;
            justify-content: flex-end;
        }
    }



    @media (max-width: 992px) {
        width: 100%;
        height: 100%;
        background-color: #0C153B;
        overflow: hidden;
        overflow-y: auto;

        .kr-manager-nav,
        .kr-manager-filter {
            display: none;
        }

        .card {
            background-color: #242644;
        }

        .user-data-card,
        .user-info-card {
            display: block;
            width: 100%;
        }

        .user-info-card {
            
            .user-profile {
                width: 80px;
                height: 80px;
                position: relative;

                img {
                    width: 80px;
                    height: 80px;
                    border-radius: 50%; 
                    color: #fff;
                }

                input {
                    position: absolute;
                    width: 30px;
                    height: 30px;
                    right: 0;
                    top: 6px;
                    border: 0;
                    border-radius: 50%;
                    padding: 0;
                    line-height: 30px;
                    text-indent: -100000000000000000rem;
                    background-color: #0134d4;
                }

                .icon {
                    position: absolute;
                    top: 14px;
                    right: 8px;
                    font-size: 12px;
                    color: #ffffff;
                    cursor: pointer;
                }
            }

            .user-info {
                flex: 1;
                word-break: break-all;
            }
        }

        .user-data-card {
            .form-group {
                .form-label {
                    font-size: 14px;
                    color: #8480ae;
                    font-weight: 500;
                }

                .form-control {
                    transition-duration: 500ms;
                    border-style: solid;
                    padding: 0.5rem 0.75rem;
                    height: 40px;
                    font-size: 14px;
                    color: #073984;
                    background-color: #ffffff;
                    border-width: 2px;
                    border-color: #ebebeb;

                    color: #ffffff;
                    background-color: #242644;
                    border-color: rgba(255, 255, 255, 0.1);

                    &:focus {
                        background-color: #cfe2ff;
                        border-color: #cfe2ff;
                        color: #073984;
                    }
                }
            }
        }

        .kr-admin-table {
            display: none;
        }
    }
}

.bv-change-password-page {
    width: 100%;

    .kr-manager-filter {
        display: flex;
        justify-content: space-between;
        padding: 15px;
        padding-bottom: 0px;

        .btn {
            font-weight: 600;
            color: #fff;
        }
    }

    .kr-admin-table {
        box-sizing: border-box;
        width: 100%;
        padding: 15px;

        .btn {
            color: #fff;
        }

        .kr-admin-line {
            display: flex;
            box-sizing: border-box;
            width: 100%;
            padding: 15px;
            padding-bottom: 0px;
            align-items: flex-start;

            & > div {
                box-sizing: border-box;
                margin-right: 7px;
                margin-left: 7px;
                padding: 20px;
                background: #fff;
                box-shadow: 0px 2px 5px 0px #00000073;
                flex: 1;

                &:first-child {
                    margin-left: 0;
                }
            }

            & > div.kr-admin-field {
                display: flex;
                align-items: flex-start;
            }

            div.kr-admin-field {

                & > div {
                    &:first-child {
                        width: 250px;
                        min-width: 250px;
                        font-weight: 700;
                    }

                    &:last-child {
                        flex: 1;
                    }
                }
            }
        }

        .kr-admin-line.kr-admin-line-cls {
            align-items: stretch;
            flex-direction: column;

            & > div {
                margin: 0;
                border-bottom: 1px solid #e9e9e9;
                box-shadow: none;
            }
        }
    }
}

.bv-verify-page {
    display: flex;
    width: 100%;
    flex-direction: row;

    .kr-manager-filter {
        display: flex;
        justify-content: space-between;
        padding: 15px;
        padding-bottom: 0px;

        .btn {
            font-weight: 600;
            color: #fff;
        }
    }

    .kr-admin-table {
        box-sizing: border-box;
        width: 100%;
        padding: 15px;

        .kr-admin-line {
            display: flex;
            box-sizing: border-box;
            width: 100%;
            padding: 15px;
            padding-bottom: 0px;
            align-items: flex-start;

            & > div {
                box-sizing: border-box;
                margin-right: 7px;
                margin-left: 7px;
                padding: 20px;
                background: #fff;
                box-shadow: 0px 2px 5px 0px #00000073;
                flex: 1;

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }
            }

            & > .kr-admin-field {
                display: flex;
                align-items: flex-start;

                & > div {
                    &:first-child {
                        width: 250px;
                        min-width: 250px;
                        font-weight: 700;
                    }

                    &:last-child {
                        flex: 1;
                    }

                    span {
                        color: #777;
                        font-size: 13px;
                    }

                    .btn {
                        display: flex;
                        min-width: 107px;
                        width: fit-content;
                        height: 39px;
                        color: #fff;
                        border: none;
                        border-radius: 3px;
                        background: #363c4f;
                        outline: none;
                        text-decoration: none;
                        align-items: center;
                        font-size: 15px;
                        justify-content: center;
                        cursor: pointer;

                        &:hover {
                            background: #3f4559;
                        }
                    }
                }
            }
        }

        .kr-admin-line.kr-admin-line-cls {
            align-items: stretch;
            flex-direction: column;
            color: #000;

            & > div {
                margin: 0;
                border-bottom: 1px solid #e9e9e9;
                box-shadow: none;

                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }

    .kr-admin-table-mobile {
        display: none;
    }

    @media (max-width: 992px) {
        display: block;
        height: 100%;
        background-color: #0C153B;
        overflow: hidden;
        overflow-y: auto;

        .kr-manager-nav,
        .kr-leftside,
        .kr-admin-table {
            display: none;
        }

        .kr-admin-table-mobile {
            display: block;
            color: #8480ae;

            .btn-success {
                color: #ffffff;
                background-color: #2ecc4a;
                border-color: #2ecc4a;
                font-weight: 500;
                font-size: 1rem;
                padding: 10px 22px;
                height: auto;
            }

            .card {
                background-color: #242644;
            }
        }
    }
}