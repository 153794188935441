.bv-quicktrading-page {
    width: 100%;

    .trading {
        position: relative;
        box-sizing: content-box;
        width: 100%;
        min-width: 500px;
        height: 600px;
        margin: 16px;
        margin-right: 290px;
        padding: 0 !important;
    }

    .kr-dash-pan-cry {
        width: 100%;
        height: auto;
        margin-bottom: 10px;
        padding-right: 3px;
        padding-left: 3px;
        position: relative;
        display: flex;
        box-sizing: border-box;
        flex-direction: column;

        .select-css {
            display: block;
            font-size: 16px;
            font-family: sans-serif;
            font-weight: 700;
            color: white;
            line-height: 1.3;
            padding: 0.6em 1.4em 0.5em 0.8em;
            width: 100%;
            max-width: 100%;
            box-sizing: border-box;
            margin: 0;
            border: 1px solid #aaa;
            box-shadow: 0 1px 0 1px rgba(0, 0, 0, .04);
            border-radius: 0.5em;
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            background-color: black;
        }

        .mtbts {
            background-color: transparent;
            background-repeat: no-repeat;
            border: none;
            margin-top: 10px;
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            padding: 10px 0px;
            border-radius: 3px;
            color: #fff;
            height: 50px;
            font-weight: bold;
            cursor: pointer;
            text-transform: uppercase;
            position: relative;
            flex-direction: column;
        }

        & > div.kr-dash-pan-action {
            position: absolute;
            right: 0px;
            top: 0px;
            bottom: 0px;
            width: 250px;
            padding: 2px;
            padding-right: 16px;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            background: transparent;
            z-index: 20;

            & > span {
                text-transform: uppercase;
                font-size: 13px;
                color: #f4f6f9 !important;
            }

            & > div.kr-dash-pan-action-btn {
                margin-top: 0px;
                background: #da4830;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px 0px;
                border-radius: 3px;
                color: #fff;
                height: 60px;
                font-weight: bold;
                cursor: pointer;
                text-transform: uppercase;
                position: relative;
                flex-direction: column;

                &:hover {
                    background: #c13822;
                }
            }

            & > div.kr-dash-pan-action-btn.kr-dash-pan-action-btn-buy {
                background: #2bab3f;

                &:hover {
                    background: #229634;
                }
            }

            & > div.kr-dash-pan-action-qtd {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                margin-top: 10px;
                min-height: 35px;

                & > label {
                    color: #fff;
                    font-size: 13px;
                }

                & > span {
                    font-size: 26px;
                    color: #33d05a;

                    p {
                        margin-block-start: 1em;
                        margin-block-end: 1em;
                        margin-inline-start: 0px;
                        margin-inline-end: 0px
                    }
                }
            }

            & > div.kr-dash-pan-action-amount {
                display: flex;
                flex-direction: column;
                position: relative;
                min-height: 20px;
            }
        }
    }

    .crypto-table {
        margin-left: auto;
        margin-right: auto;
        margin-top: 0;

        tr {
            border-bottom: 2px solid var(--ct-foreground);
            text-align: left;
        }

        td {
            border-right: solid 1px var(--ct-foreground);
            border-left: solid 1px var(--ct-foreground);
        }

        th {
            border-right: solid 1px var(--ct-accent-dark);
            border-left: solid 1px var(--ct-accent-dark);
        }

        th,
        td {
            padding: 12px 10px;
            min-width: 60px;
        }
    }

    .owl-carousel {
        display: none;
    }

    .affan-features-wrap {
        display: none;
    }

    @media (max-width: 992px) {
        background-color: #0C153B;
        height: 100%;
        overflow: hidden;
        overflow-y: auto;

        .kr-dash-pan-cry {
            display: none;
        }

        .kr-leftside {
            display: none;
        }

        .trading {
            min-width: unset;
            height: 300px;
            box-sizing: border-box;
            margin: 0;
            // display: none;
        }

        .owl-carousel {
            display: block;
            height: 300px;
            width: 100%;

            .owl-stage-outer {
                position: relative;
                overflow: hidden;
                height: 100%;
                transform: translate3d(0, 0, 0);

                .owl-stage {
                    transition: all 0s ease 0s;
                    width: 100%;
                    height: 100%;
                    // transform: translate3d(-2010px, 0px, 0px);

                    iframe {
                        height: 100%;
                        width: 100%;
                    }
                }
            }
            
        }

        .affan-features-wrap {
            display: block;
            color: #8480ae;

            .card {
                background-color: #242644;

                .table {
                    color: rgba(255, 255, 255, 0.7);
                    border-color: rgba(255, 255, 255, 0.2);
                }

                .form-group {
                    line-height: 1;
                    margin-bottom: 1rem;

                    .form-control {
                        color: #ffffff;
                        background-color: #242644;
                        border-color: rgba(255, 255, 255, 0.1);
                        transition-duration: 500ms;
                        border-style: solid;
                        padding: 0.5rem 0.75rem;
                        height: 40px;
                        font-size: 14px;
                        border-width: 2px;
                    }
                }
            }
        }
    }
}

.bv-trading-history-page {
    width: 100%;
    display: block;

    .kr-manager-filter {
        display: flex;
        justify-content: space-between;
        padding: 15px;
        padding-bottom: 0px;

        .btn {
            font-weight: 600;
            color: #fff;
        }
    }

    .kr-admin-table {
        box-sizing: border-box;
        width: 100%;
        padding: 15px;
        color: #000;

        table {
            box-sizing: border-box;
            width: 100%;
            max-width: 100%;
            border-collapse: collapse;
            background: #fff;
            box-shadow: 0px 2px 5px 0px #00000073;

            thead {
                background: #e9ecf2;
                font-weight: 600;

                tr {
                    td {
                        padding: 10px;
                    }
                }
            }

            tbody {
                tr {
                    border-bottom: 1px solid #f2f2f2;

                    td {
                        padding: 9px 10px;
                    }
                }
            }
        }
    }
}

.bv-trading-history-mobile {
    display: none;
}

@media (max-width: 992px) {
    .bv-trading-history-page {
        display: none;
    }

    .bv-trading-history-mobile {
        display: block;
        background-color: #0C153B;
        min-height: 100%;
        width: 100%;

        .mobile-table {
            display: block;
    
            .element-heading {
                position: relative;
                z-index: 1;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
            }
    
            .card {
                background-color: #242644;
    
                .table {
                    color: rgba(255, 255, 255, 0.7);
                    border-color: rgba(255, 255, 255, 0.2);
                }
    
                .form-group {
                    line-height: 1;
                    margin-bottom: 1rem;
    
                    .form-control {
                        color: #ffffff;
                        background-color: #242644;
                        border-color: rgba(255, 255, 255, 0.1);
                        transition-duration: 500ms;
                        border-style: solid;
                        padding: 0.5rem 0.75rem;
                        height: 40px;
                        font-size: 14px;
                        border-width: 2px;
                    }
                }
            }
        }
    }
}