.bv-trading-menu-modal {
    padding: 20px;
    background: #181f2c;
    width: fit-content;
    position: relative;
    transition: all 5s ease-in-out;
    margin: 0 auto;

    h2 {
        margin-top: 0;
        margin-bottom: 16px;
        color: white;
        font-family: Tahoma, Arial, sans-serif;
    }

    .close2 {
        position: absolute;
        top: 20px;
        right: 30px;
        transition: all 200ms;
        font-size: 30px;
        font-weight: bold;
        text-decoration: none;
        color: red;
        cursor: pointer;

        &:hover {
            color: #06D85F;
        }
    }

    .content {
        max-height: 30%;
        overflow: auto;

        nav.kr-manager-nav2 {
            width: 100%;

            .no-link {
                text-decoration: none;
                color: rgb(85, 26, 139);
            }

            ul {
                margin: 0;
                padding: 0;
                display: flex;
                background: #fff;

                & > li {
                    margin: 0;
                    padding: 15px 15px 10px 15px;
                    color: black;
                    border-bottom: 5px solid #fff;
                    text-align: center;
                    text-transform: uppercase;
                    font-size: 14px;
                    font-weight: bold;
                    cursor: pointer;
                    border-right: 1px solid #e6e6e6;
                }
            }
        }
    }
}