@keyframes animateSuccessTip {
    0% {
        width: 0;
        left: 1px;
        top: 19px;
    }

   54% {
       width: 0;
       left: 1px;
       top: 19px;
   }

    70% {
        width: 50px;
        left: -8px;
        top: 37px;
    }

    84% {
        width: 17px;
        left: 21px;
        top: 48px;
    }

    100% {
        width: 25px;
        left: 14px;
        top: 45px;
    }
}

@keyframes animateSuccessLong {
    0% {
        width: 0;
        right: 46px;
        top: 54px;
    }

    65% {
        width: 0;
        right: 46px;
        top: 54px;
    }

    84% {
        width: 55px;
        right: 0px;
        top: 35px;
    }

    100% {
        width: 47px;
        right: 8px;
        top: 38px;
    }
}

.bv-success-modal {
    background-color: #fff;

    .animateSuccessTip {
        animation: animateSuccessTip 0.75s;
    }

    .animateSuccessLong {
        animation: animateSuccessLong 0.75s;
    }

    .sweet-alert {
        padding: 17px;
        border-radius: 2px;
        text-align: center;
        overflow: hidden;

        h2 {
            color: #575757;
            font-size: 30px;
            text-align: center;
            font-weight: 600;
            text-transform: none;
            position: relative;
            margin: 25px 0;
            padding: 0;
            line-height: 40px;
            display: block;
        }

        p {
            color: #797979;
            font-size: 16px;
            text-align: center;
            font-weight: 300;
            position: relative;
            text-align: inherit;
            float: none;
            margin: 0;
            padding: 0;
            line-height: normal;
        }

        .sa-icon {
            width: 80px;
            height: 80px;
            border: 4px solid gray;
            border-radius: 50%;
            margin: 20px auto;
            padding: 0;
            position: relative;
            box-sizing: content-box;
        }

        .sa-icon.sa-success {
            border-color: #A5DC86;

            .sa-line {
                height: 5px;
                background-color: #A5DC86;
                display: block;
                border-radius: 2px;
                position: absolute;
                z-index: 2;
            }

            .sa-line.sa-tip {
                width: 25px;
                left: 14px;
                top: 46px;
                transform: rotate(45deg);
            }

            .sa-line.sa-long {
                width: 47px;
                right: 8px;
                top: 38px;
                transform: rotate(-45deg);
            }
        }

        .sa-button-container {
            .sa-confirm-button-container {
                button.confirm {
                    display: inline-block;
                    background-color: rgb(140, 212, 245);
                    outline: none;
                    border: none;
                    color: #fff;
                    font-size: 17px;
                    border-radius: 2px;
                    padding: 10px 32px;
                    margin: 26px 5px 0 5px;
                    font-weight: 500;
                }
            }
        }
    }
}