.kr-leftside {
    display: flex;
    height: 100%;
    background: #20283a;
    flex-direction: column;
    box-shadow: 2px 0px 20px #00000030;
    position: relative;

    .btn {
        color: #fff;
        font-weight: 600;
    }

    div.kr-leftside-hide-controller {
        position: absolute;
        background: rgba(255, 255, 255, 0.11);
        z-index: 99999;
        right: -10px;
        color: #fff;
        width: 10px;
        font-size: 8px;
        height: 30px;
        border-radius: 0px 3px 3px 0px;
        top: calc(50% - 15px);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 0px 1px;
        box-sizing: border-box;
    }

    div.kr-wtchl {
        width: 274px;
        background: #1d2435;
        flex: 1;
        display: flex;
        flex-direction: column;

        &>header {
            display: flex;
            height: 40px;
            min-height: 40px;
            max-height: 40px;
            background: #181e2b;
            align-items: center;
            justify-content: space-between;
            padding-right: 5px;
            margin: 6px 4px;
            border-radius: 0px;

            &>span {
                padding: 10px;
                color: #949fab;
                font-size: 12px;
                text-transform: uppercase;
                font-weight: 500;
            }
        }

        &>ul {
            margin: 0;
            padding: 0;
            display: flex;
            height: calc(100% - 73px);
            overflow-x: hidden;
            overflow-y: auto;
            flex-direction: column;
            position: relative;
            flex: 1;

            &>li {
                margin: 0;
                display: flex;
                align-items: center;
                padding: 9px 7px 9px 10px;
                color: #c5c8cc;
                font-weight: 500;
                border-left: 3px solid transparent;
                cursor: pointer;
                position: relative;
                min-height: 34px;
                box-sizing: border-box;

                &:hover {
                    background: #262b3e;
                }
            }
        }
    }

    div.kr-infoscurrencylf {
        background: #171b29;
        width: 274px;
        overflow: hidden;
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow-y: auto;

        &>header {
            display: flex;
            height: 40px;
            min-height: 40px;
            max-height: 40px;
            background: #262b3e;
            align-items: center;
            justify-content: space-between;
            padding-right: 5px;
            margin: 6px 4px;
            border-radius: 0px;

            &>span {
                padding: 10px;
                color: #98aaba;
                font-size: 12px;
                text-transform: uppercase;
                font-weight: 500;
            }
        }

        &>ul {
            padding: 0;
            display: flex;
            flex-direction: column;
            margin: 0px 10px;
            margin-top: 10px;
            min-height: 108px;

            &>li {
                margin: 0;
                display: flex;
                align-items: center;
                color: #c5cbce;
                font-size: 13px;
                text-transform: uppercase;
                padding: 6px 0px;
            }
        }

        div.kr-infoscurrencylf-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 15px 0px;
        }
    }
}