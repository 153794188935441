@keyframes bounce_fountainG {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    50% {
        -webkit-transform: translateY(15px);
        transform: translateY(15px);
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

.page {
    background-color: #161634;
}

.heading-title {
    font-size: 48px;
    margin-bottom: 70px;
    color: #fff;
    text-transform: capitalize;
    letter-spacing: 1px;
}

.bg-color-cu {
    background-color: #060826;
}

.section-padding-100-50 {
    padding-top: 100px;
    padding-bottom: 50px;
}

.section-padding-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.mb-100 {
    margin-bottom: 100px;
}

.mb-50 {
    margin-bottom: 50px;
}

.breadcrumb-area {
    background-image: url(../../assets/images/bg-shape-2.png);
    height: 350px;
    background-color: #161634;

    .breadcrumb-title {
        padding-top: 45px;

        h2 {
            font-size: 30px;
            font-weight: 600;
            margin-bottom: 10px;
            font-family: 'Roboto', sans-serif;
            color: #fd562a;
            text-transform: capitalize;
            letter-spacing: 3px;
        }

        ul {
            margin: 0;
            padding: 0;
        }

        .breadcrumb-list {
            li {
                color: #fff;
                font-weight: 600;
                margin: 0 5px;
                display: inline-block;
            }
        }
    }
}

.bv-home-page {
    background-color: #161634;
    margin-top: -106px;

    .btn-area {
        .btn-box {
            position: relative;
            display: inline-block;
            overflow: hidden;
            font-size: 17px;
            font-weight: 400;
            font-family: 'Roboto', sans-serif;
            text-align: center;
            background-image: linear-gradient(to left top, #fd562a, rgba(211, 40, 83, 0.70), rgba(148, 31, 95, 0.80), #4f2155, #161634);
            border: none;
            border-radius: 30px;
            text-align: center;
            height: 50px;
            z-index: 1;
            width: 170px;
            line-height: 50px;
            padding: 0;
            color: #fff;

            &::before {
                position: absolute;
                content: '';
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                font-weight: 400;
                opacity: 0;
                color: #fd562a;
                background-color: #fff;
                border: none;
                transition: all 0.4s;
                transform: scale(0.2, 1);
                z-index: -1;
            }

            &:hover {
                color: #fd562a;

                &::before {
                    opacity: 1;
                    transform: scale(1, 1);
                }
            }
        }
    }

    .mt-50 {
        margin-top: 50px;
    }

    &__welcome-area-start {
        height: 800px;
        background-image: url(../../assets/images/h-shape.jpg);
        padding-top: 100px;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;

        &__welcome-content {
            h3 {
                font-size: 46px;
                text-transform: capitalize;
                font-family: 'Roboto', sans-serif;
                line-height: 1.4;
                font-weight: 700;
                color: #fff;

                span {
                    -webkit-text-stroke-width: 1.9px;
                    -webkit-text-stroke-color: #fd562a;
                    color: transparent;
                    text-transform: uppercase;
                    letter-spacing: 4.2px;
                }
            }

            p {
                font-size: 20px;
                margin-bottom: 40px;
                color: rgba(255, 255, 255, 0.85);
                font-family: 'Roboto', sans-serif;
                font-weight: 400;
                line-height: 1.7;
                letter-spacing: 0.5px;
            }
        }
    }

    &__wallet-area {
        z-index: 1;
        position: relative;

        &::after {
            position: absolute;
            content: "";
            background-color: rgba(15, 8, 38, 0.50);
            height: 15px;
            width: 100%;
            z-index: -1;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
        }

        .single-wallet-area {
            border: 1px solid transparent;
            border-left: 6px solid rgba(253, 88, 42, 0.25);
            transition-duration: 900ms;
            background-color: #0f0826;
            padding: 60px 20px;
            border-radius: 20px;  
            margin-bottom: 50px;
            
            .wallet-content-text {
                p {
                    margin-bottom: 0;
                    font-size: 15px;
                    color: rgba(255, 255, 255, 0.85);
                    font-family: 'Roboto', sans-serif;
                    font-weight: 400;
                    line-height: 1.7;
                    letter-spacing: 0.5px;
                }
            }
        }
    }

    &__about-us-area {
        .about-image-area {
            position: relative;
            z-index: 1;

            .about-feature-img {
                position: absolute;
                top: -60px;
                left: -50px;
                z-index: 9;

                @media (min-width: 992px) and (max-width: 1199px) {
                    left: 0;
                }
            }

            .welcome-thumb {
                animation-delay: 1s;
                animation-name: bounce_fountainG;
                animation-duration: 3s;
                animation-iteration-count: infinite;
                animation-duration: normal;
            }

            .about-image-2 {
                position: relative;
                z-index: 1;
            }
        }
        
        .about-content-text {
            h2 {
                font-size: 54px;
                margin-bottom: 20px;
                line-height: 1.2;
            }

            p {
                font-size: 17px;
                color: rgba(255, 255, 255, 0.85);
                font-family: 'Roboto', sans-serif;
                font-weight: 400;
                line-height: 1.7;
                letter-spacing: 0.5px;
            }
        }
    }

    &__our-team-area {
        .single-team-area.team-2 {
            padding: 0;
            background-color: transparent;
        }

        .single-team-area {
            border-radius: 15px;
            margin-bottom: 50px;
            -webkit-transition-duration: 800ms;
            -o-transition-duration: 800ms;
            transition-duration: 800ms;
        }

        .team-content-text {
            h4 {
                font-size: 20px;
                margin-top: 20px;
            }

            h6 {
                font-size: 14px;
                font-weight: 500;
                margin-bottom: 0;
            }
        }
    }
}

.bv-about-page {
    margin-top: -106px;
    background-color: #161634;

    .bg-overlay {
        position: relative;
        z-index: 1;

        &::before {
            position: absolute;
            content: "";
            background-color: #060826;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            opacity: 0.92;
        }
    }

    .bg-img {
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }

    .about-us-area {
        background-image: url(../../assets/images/bg-shape.jpg);

        .about-us-content {
            p {
                font-size: 17px;
                color: rgba(255, 255, 255, 0.85);
                font-family: 'Roboto', sans-serif;
                font-weight: 400;
                line-height: 1.7;
                letter-spacing: 0.5px;
            }
        }

        .welcome-thumb {
            animation-delay: 1s;
            animation-name: bounce_fountainG;
            animation-duration: 3s;
            animation-iteration-count: infinite;
            animation-duration: normal;
        }

        .single-counter-2 {
            background-image: linear-gradient(to left top, #fd562a, rgba(211, 40, 83, 0.98), rgba(148, 31, 95, 0.90), #4f2155, #161634);
            padding: 40px 20px;
            border-radius: 10px;

            .counter-number-2 {
                p {
                    font-size: 18px;
                    margin-bottom: 0;
                    color: rgba(255, 255, 255, 0.85);
                    font-family: 'Roboto', sans-serif;
                    font-weight: 400;
                    line-height: 1.7;
                }
            }
        } 
    }
}

.bv-trading-plans-page {
    margin-top: -106px;
    background-color: #161634;

    .prix {
        margin: 0;
        padding: 10px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        font-size: 62.5%;
        font-family: 'Roboto', Arial, Helvetica, Sans-serif, Verdana;
            background: transparent;
        color: #FFF;
    }
        
    .pricing-table-title {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 2.6em;
        color: #FFF;
        margin-top: 15px;
        text-align: left;
        margin-bottom: 25px;
        text-shadow: 0 1px 1px rgba(0,0,0,0.4);
    }

    .pricing-table-title a {
        font-size: 0.6em;
    }

    .clearfix:after {
        content: '';
        display: block;
        height: 0;
        width: 0;
        clear: both;
    }
    /** ========================
    * Contenedor
    ============================*/
    .pricing-wrapper {
        width: auto;
        margin: 40px auto 0;
    }

    .pricing-table {
        margin: 0 10px;
        text-align: center;
        width: 300px;
        float: left;
        -webkit-box-shadow: 0 0 15px rgba(0,0,0,0.4);
        box-shadow: 0 0 15px rgba(0,0,0,0.4);
        -webkit-transition: all 0.25s ease;
        -o-transition: all 0.25s ease;
        transition: all 0.25s ease;
    }

    .pricing-table:hover {
        -webkit-transform: scale(1.06);
        -ms-transform: scale(1.06);
        -o-transform: scale(1.06);
        transform: scale(1.06);
    }

    .pricing-title {
        color: #FFF;
        background: #e95846;
        padding: 20px 0;
        font-size: 2em;
        text-transform: uppercase;
        text-shadow: 0 1px 1px rgba(0,0,0,0.4);
    }

    .pricing-action {
        outline: none;
        border: none;
    }

    .pricing-table.recommended .pricing-title {
        background: #2db3cb;
    }

    .pricing-table.recommended .pricing-action {
        background: #2db3cb;
    }

    .pricing-table .price {
        background: #403e3d;
        font-size: 3.4em;
        font-weight: 700;
        padding: 20px 0;
        text-shadow: 0 1px 1px rgba(0,0,0,0.4);
    }

    .pricing-table .price sup {
        font-size: 0.4em;
        position: relative;
        left: 5px;
    }

    .table-list {
        background: #FFF;
        color: #403d3a;
        margin: 0;
    }

    .table-list li {
        font-size: 1.4em;
        font-weight: 700;
        padding: 12px 8px;
        list-style: none;
    }

    .table-list li .check {
        color: #3fab91;
        display: inline-block;
        position: relative;
        right: 5px;
        font-size: 16px;
        font-weight: 700;
    } 

    .table-list li span {
        font-weight: 400;
    }

    .table-list li span.unlimited {
        color: #FFF;
        background: #e95846;
        font-size: 0.9em;
        padding: 5px 7px;
        display: inline-block;
        -webkit-border-radius: 38px;
        -moz-border-radius: 38px;
        border-radius: 38px;
    }


    .table-list li:nth-child(2n) {
        background: #F0F0F0;
    }

    .table-buy {
        background: #FFF;
        padding: 15px;
        text-align: left;
        overflow: hidden;
    }

    .table-buy p {
        float: left;
        color: #37353a;
        font-weight: 700;
        font-size: 2.4em;
    }

    .table-buy p sup {
        font-size: 0.5em;
        position: relative;
        left: 5px;
    }

    .table-buy .pricing-action {
        float: right;
        color: #FFF;
        background: #e95846;
        padding: 10px 16px;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        font-weight: 700;
        font-size: 1.4em;
        text-decoration: none;
        text-shadow: 0 1px 1px rgba(0,0,0,0.4);
        -webkit-transition: all 0.25s ease;
        -o-transition: all 0.25s ease;
        transition: all 0.25s ease;
    }

    .table-buy .pricing-action:hover {
        background: #cf4f3e;
    }

    .recommended .table-buy .pricing-action:hover {
        background: #228799;	
    }

    /** ================
    * Responsive
    ===================*/
    @media  only screen and (min-width: 768px) and (max-width: 959px) {
        .pricing-wrapper {
            width: 768px;
        }

        .pricing-table {
            width: 236px;
        }
        
        .table-list li {
            font-size: 1.3em;
        }

    }

    @media  only screen and (max-width: 767px) {
        .pricing-wrapper {
            width: 420px;
        }

        .pricing-table {
            display: block;
            float: none;
            margin: 0 0 20px 0;
            width: 100%;
        }
    }

    @media  only screen and (max-width: 479px) {
        .pricing-wrapper {
            width: 300px;
        }
    } 
}

.bv-contact-us-page {
    margin-top: -106px;
    background-color: #161634;

    .contact-meta-info-area {
        padding: 40px 20px;
        background-image: linear-gradient(to left top, #fd562a, rgba(211, 40, 83, 0.70), rgba(148, 31, 95, 0.80), #4f2155, #161634);
        border-radius: 15px;

        .contact-meta-info {
            padding-top: 30px;
            padding-bottom: 30px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.15);
                
            &:first-child {
                padding-top: 0;
            }

            .c-meta-icon {
                font-size: 36px;
                margin-bottom: 10px;
                color: #fff;
            }

            font-size: 20px;
            text-transform: capitalize;
            font-weight: 600;
            font-family: 'Roboto', sans-serif;
            color: rgba(255, 255, 255, 0.8);

            span {
                font-size: 16px;
                display: inline-block;
                letter-spacing: 1px;
            }
        }
    }

    .contact-area {
        h4 {
            font-size: 42px;
            -webkit-text-stroke-width: 1.4px;
            -webkit-text-stroke-color: rgba(255, 255, 255, 0.9);
            color: transparent;
            text-transform: uppercase;
            letter-spacing: 3px;
        }

        .nft-contact-from {
            display: block;
            margin-top: 0em;

            .form-control {
                height: 70px;
                color: rgb(255, 255, 255);
                font-size: 18px;
                background: rgb(15, 8, 38);
                border-radius: 5px;
                padding: 10px 20px;
                border-width: initial;
                border-style: none;
                border-color: initial;
                border-image: initial;
                transition: all 0.3s ease 0s;
            }

            .form-control,
            .nft-contact-from textarea {
                height: 70px;
                color: rgb(255, 255, 255);
                font-size: 18px;
                background: rgb(15, 8, 38);
                border-radius: 5px;
                padding: 10px 20px;
                border-width: initial;
                border-style: none;
                border-color: initial;
                border-image: initial;
                transition: all 0.3s ease 0s;
            }
        }

        .btn-box {
            position: relative;
            display: inline-block;
            overflow: hidden;
            font-size: 17px;
            font-weight: 400;
            font-family: 'Roboto', sans-serif;
            text-align: center;
            background-image: linear-gradient(to left top, #fd562a, rgba(211, 40, 83, 0.70), rgba(148, 31, 95, 0.80), #4f2155, #161634);
            border: none;
            border-radius: 30px;
            text-align: center;
            height: 50px;
            z-index: 1;
            width: 170px;
            line-height: 50px;
            padding: 0;
            color: #fff;

            &::before {
                position: absolute;
                content: '';
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                font-weight: 400;
                opacity: 0;
                color: #fd562a;
                background-color: #fff;
                border: none;
                transition: all 0.4s;
                transform: scale(0.2, 1);
                z-index: -1;
            }

            &:hover {
                color: #fd562a;

                &::before {
                    opacity: 1;
                    transform: scale(1, 1);
                }
            }
        }
    }
}

.bv-aml-policy-page,
.bv-payment-policy-page,
.bv-privacy-policy-page,
.bv-terms-page,
.bv-risk-disclosure-page,
.bv-ripple-trading-page,
.bv-etherum-trading-page,
.bv-litecoin-trading-page,
.bv-bitcoin-cash-trading-page,
.bv-bitcoin-trading-page {
    margin-top: -106px;
    font-size: 17px;
    color: rgba(255, 255, 255, 0.85);
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    line-height: 1.7;
    letter-spacing: 0.5px;

    p {
        margin-bottom: 1rem;
    }

    ul {
        padding: 0;
        margin: 0;
        li {
            list-style: none;
        }
    }
}

.bv-litecoin-trading-page {
    .blog-details-content h2 {
        font-size: 26px;
        text-transform: capitalize;
        letter-spacing: 1px;
        margin-top: 30px;
    }
}

.bv-financial-instruments-page {
    .single-blog-grid {
        background-color: #161634;
        padding: 30px;
        border-radius: 15px;
    }
}

.bv-verify-email-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: xx-large;
}