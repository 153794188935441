.kr-manager-nav {
    width: 100%;

    ul {
        background-color: #fff;
        display: flex;
        margin: 0;
        padding: 0;

        li {
            padding: 10px 10px 8px 10px;
            color: #3f4858;
            border-bottom: 5px solid #fff;
            text-align: center;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: bold;
            cursor: pointer;
            border-right: 1px solid #e6e6e6;

            &:hover {
                border-bottom-color: #f4f4f4;
                background: #f4f4f4;
            }

            a {
                text-decoration: none;
            }
        }

        li.kr-manager-nav-selected {
            color: #181f2c;
            border-bottom-color: #ff7700;
        }
    }
}