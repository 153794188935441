$font-family-base: 'Poppins' !default;

// Bootstrap
@import '~bootstrap/scss/bootstrap.scss';

html {
  font-size: 16px;
}

@font-face {
  font-family: "Oxanium";
  src: local("Oxanium"),
    url("./assets/fonts/Oxanium/Oxanium-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), 
    url("./assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-size: 17px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.85);
  background-color: #1d2435 !important;
  line-height: 1.7;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.input-error {
  color: red;
  opacity: 0;
  animation: fadeIn 0.5s forwards;
}

.api-error {
  opacity: 0;
  animation: fadeIn 0.5s forwards;
}

input[type=number] {
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    display: none;
  }
  
}

.btn {
  display: flex;
  min-width: 107px;
  width: fit-content;
  height: 39px;
  color: #fff;
  border: none;
  border-radius: 3px;
  background: #363c4f;
  outline: none;
  text-decoration: none;
  align-items: center;
  font-size: 15px;
  justify-content: center;
  cursor: pointer;
}

.btn.btn-green {
  background: #29c359;

  &:hover {
    background: #29c359;
  }
}

.btn.btn-orange {
  background: #ff7700;

  &:hover {
    background: #fe8114;
  }
}

.btn.btn-red {
  background: #e30f0f;

  &:hover {
    background: #cf1010;
  }
}

.btn.btn-autowidth {
  width: auto;
  padding: 0px 10px;
}

.btn.btn-big {
  font-size: 15px;
  height: 41px;
  text-transform: uppercase;
}

.btn.btn-small {
  font-size: 13px;
  height: 27px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Oxanium', cursive;
  color: #fff;
  font-weight: 700;
  line-height: 1.3;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
}

ul {
  margin: 0;
  padding: 0;

  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

.kr-mono {
  font-family: 'Roboto Mono', 'Roboto', monospace, sans-serif;
}

img {
  max-width: 100%;
  height: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
  transition: filter 0.2s linear;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  // display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
