.bv-auth-layout {
    height: 100vh;
    width: 100%;
    position: relative;
    background-color: #111418 !important;

    &__feedback-button {
        height: 40px;
        border: solid 3px red;
        background: red;
        width: 100px;
        line-height: 32px;
        font-weight: 600;
        color: white;
        transform: rotate(-90deg);
        text-align: center;
        font-size: 17px;
        position: fixed;
        right: -40px;
        z-index: 999;
        cursor: pointer;
        text-decoration: none;
        top: 45%;
        font-family: "Roboto", helvetica, arial,
    }

    .bg-dark {
        background-color: #111418 !important;
    }

    .hero-wrap {
        position: relative;
        overflow: hidden;
        background-image: url(../../assets/images/jaga-main.png);
        background-position: center center;
        background-attachment: scroll;
        background-size: cover;
        background-color: #fff;

        .hero-mask {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: 1;
            opacity: 0.5;
        }
        

        .hero-content {
            position: relative;
            z-index: 2;
        }
    }
}