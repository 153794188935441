.bv-login-modal {
    background-color: #fff;
    padding-top: 20px;

    .mb-30 {
        margin-bottom: 30px;
    }

    .nav {
        display: flex;
        flex-direction: row;
        gap: 16px;
        justify-content: center;
        align-items: center;
    }

    .nav-item {
        color: #fff;
        background-color: #EC5A1F;
        text-align: center;
        border-radius: 22px;
        font-weight: 500;
        cursor: pointer;
        font-size: 16px;
        padding: 5px 25px;
        display: inline-block;
        text-decoration: none;
        transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;

        &:last-child {
            color: #212529;
            font-weight: 500;
            font-size: 16px;
            padding: 5px 25px;
            display: inline-block;
            background: none;
        }
    }

    .content {
        padding: 60px 30px;

        .modal-title {
            font-size: 30px;
            text-transform: uppercase;
            text-align: center;
            margin-bottom: 40px;
            color: #212529;
            line-height: 1.5;
            font-family: 'Oxanium', cursive;
            font-weight: 700;
        }
    }

    .col-form-label {
        color: #212529;
        font-size: 13px;
        margin-bottom: 3px;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: calc(0.375rem + 1px);
        padding-bottom: calc(0.375rem + 1px);
        line-height: 1.5;
    }

    .form-control {
        padding: 10px;
        font-size: 16px;
        font-weight: 500;
        color: #212529;
        background-color: #fff;
        background-clip: padding-box;
        border: none;
        border: 1px solid rgba(33, 37, 41, 0.30);
        border-radius: 0;
    }

    .forgot-btn {
        text-align: right;
        text-decoration: none;
        font-family: 'Roboto', sans-serif;
    }

    .login-submit {
        background-color: #fd562a;
        display: block;
        width: 100%;
        margin-top: 30px;
        border: none;
        padding: 10px;
        font-size: 16px;
        color: #fff;
        text-transform: uppercase;
        font-weight: 600;
        border-radius: 22px;
        transition-duration: 500ms;
    }
}