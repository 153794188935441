.bv-deposit-fund-page {
    width: 100%;
    display: flex;
    flex-direction: row;

    .kr-manager-filter {
        display: flex;
        justify-content: space-between;
        padding: 15px;
        padding-bottom: 0px;

        .btn {
            font-weight: 600;
            color: #fff;
        }
    }

    .kr-admin-table {
        box-sizing: border-box;
        width: 100%;
        padding: 15px;

        .kr-admin-line {
            display: flex;
            box-sizing: border-box;
            width: 100%;
            padding: 15px;
            padding-bottom: 0px;
            align-items: flex-start;

            & > div {
                box-sizing: border-box;
                margin-right: 7px;
                margin-left: 7px;
                padding: 20px;
                background: #fff;
                box-shadow: 0px 2px 5px 0px #00000073;
                flex: 1;
            }

            div.kr-admin-field {
                display: flex;
                align-items: flex-start;
                color: black;

                & > div {
                    &:first-child {
                        width: 250px;
                        min-width: 250px;
                        font-weight: 700;
                    }
                }
            }

            & > div {
                &:first-child {
                    margin-left: 0;
                }
            }
        }

        .kr-admin-line.kr-admin-line-cls {
            align-items: stretch;
            flex-direction: column;

            & > div { 
                margin: 0;
                border-bottom: 1px solid #e9e9e9;
                box-shadow: none;
            }
        }

        .payment-block {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
            grid-gap: 20px;
            text-align: center;

            label {
                padding: 2rem 1rem;
                border: 2px dashed #ddd;
                border-radius: 10px;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                transition: all 0.5s ease-in-out;
                min-height: 140px;
                background: #fff;
                flex-direction: column;
                overflow: hidden;
            }
        }
    }
}

.bv-deposit-fund-mobile {
    display: none;
}

.bv-deposit-log-page {
    width: 100%;
    display: flex;
    flex-direction: row;

    .kr-manager-filter {
        display: flex;
        justify-content: space-between;
        padding: 15px;
        padding-bottom: 0px;

        .btn {
            font-weight: 600;
            color: #fff;
        }
    }

    div.kr-admin-table {
        box-sizing: border-box;
        width: 100%;
        padding: 15px;

        table {
            box-sizing: border-box;
            width: 100%;
            max-width: 100%;
            border-collapse: collapse;
            background: #fff;
            box-shadow: 0px 2px 5px 0px #00000073;
            color: #000;

            thead {
                background: #e9ecf2;
                font-weight: 600;

                td {
                    padding: 10px;
                }
            }

            tbody {
                tr {
                    border-bottom: 1px solid #f2f2f2;

                    td {
                        padding: 9px 10px;
                    }
                }
            }
        }
    }
}

.bv-deposit-history-mobile {
    display: none;
}

@media (max-width: 992px) {
    .bv-deposit-log-page,
    .bv-deposit-fund-page {
        display: none;
    }

    .bv-deposit-history-mobile {
        display: block;
        width: 100%;
        min-height: 100%;
        background-color: #0C153B;

        .mobile-table {
            display: block;
    
            .element-heading {
                position: relative;
                z-index: 1;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
            }
    
            .card {
                background-color: #242644;
    
                .table {
                    color: rgba(255, 255, 255, 0.7);
                    border-color: rgba(255, 255, 255, 0.2);
                }
    
                .form-group {
                    line-height: 1;
                    margin-bottom: 1rem;
    
                    .form-control {
                        color: #ffffff;
                        background-color: #242644;
                        border-color: rgba(255, 255, 255, 0.1);
                        transition-duration: 500ms;
                        border-style: solid;
                        padding: 0.5rem 0.75rem;
                        height: 40px;
                        font-size: 14px;
                        border-width: 2px;
                    }
                }
            }
        }
    }

    .bv-deposit-fund-mobile {
        display: block;
        width: 100%;
        min-height: 100%;
        background-color: #0C153B;

        .card {
            background-color: #242644;
            border-radius: 0.5rem;
            height: fit-content;

            .card-body {
                padding: 1.5rem;

                .btn {
                    height: fit-content;
                    color: #ffffff;
                    background-color: #061238;
                    border-color: #061238;
                }
            }
        }
    }
}