.bv-register-page,.bv-login-page, .bv-forgot-password-page, .bv-complete-profile-page, .bv-user-identification-page, .bv-reset-password-page {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .text-light {
        color: #dee3e4 !important;
        opacity: 1;
    }

    .form-control {
        background-color: #232a31;
        color: #fff;
        font-size: 16px;
        border: 1px solid #ced4da;
        appearance: none;
        border-radius: 0.25rem;
        transition: border-color, opacity .15s ease-in-out, box-shadow .15s ease-in-out;
        font-weight: 400;
        line-height: 1.5;
        border-color: #232a31;
        display: block;
        width: 100%;
        outline: none;

        &:disabled {
            opacity: 0.5;
        }
    }

    .form-control:not(.form-control-sm) {
        padding: 0.81rem 0.96rem;
        height: inherit;
    }

    .btn:not(.btn-link) {
        -webkit-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
    }

    .btn-primary {
        background-color: #0d6efd;
        border-color: #0d6efd;

        height: auto;
    }

    a {
        text-decoration: none;
        cursor: pointer;
    }

    .btn {
        padding: 0.8rem 2.6rem;
        font-weight: 500;
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease;
        transition: opacity 0.5s ease-in;

        &:disabled {
            opacity: 0.7;
        }
    }

    hr {
        opacity: 0.2;
        height: 2px;
        // width: 100%;
    }

    .bg-dark-4 {
        background-color: #6c757d !important;
    }

    .fw-400 {
        font-weight: 600 !important;
    }

    .btn {
        transition: all 0.5s ease;
    }

    .btn-sm {
        padding: 0.5rem 1rem;
    }

    .btn-primary {
        background-color: #0d6efd;
        border-color: #0d6efd;
    }
}