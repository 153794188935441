.bv-btc-deposit-modal {
    .modal-content {
        position: relative;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 100%;
        pointer-events: auto;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 0.3rem;
        outline: 0;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .1);

        .modal-header {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-align: start;
            align-items: flex-start;
            -ms-flex-pack: justify;
            justify-content: space-between;
            padding: 1rem;
            border-bottom: 1px solid #e9ecef;
            border-top-left-radius: 0.3rem;
            border-top-right-radius: 0.3rem;
        }

        .modal-body {
            padding: 1rem;

            label {
                padding: 2rem 1rem;
                border: 2px dashed #ddd;
                border-radius: 10px;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                transition: all 0.5s ease-in-out;
                min-height: 140px;
                background: #fff;
                flex-direction: column;
                overflow: hidden;
            }

            .conversion1 {
                color: black;
                margin-top: 16px;
            }

            .form-group {
                margin-bottom: 1.429rem;
            }

        }

        .modal-footer {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-align: center;
            align-items: center;
            -ms-flex-pack: end;
            justify-content: flex-end;
            padding: 1rem;
            border-top: 1px solid #e9ecef;

            & > :not(:last-child) {
                margin-right: 0.5rem;
            }

            .btn {
                display: flex;
                width: 107px;
                height: 39px;
                color: #fff;
                border: none;
                border-radius: 3px;
                background: #363c4f;
                outline: none;
                text-decoration: none;
                align-items: center;
                font-size: 15px;
                justify-content: center;
                cursor: pointer;

                &:hover {
                    background: #3f4559;
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .bv-btc-deposit-modal {
        .modal-content {
            color: #8480ae;
            background-color: #242644;

            .modal-header {
                border-bottom-color: rgba(255, 255, 255, 0.1);

                .close {
                    color: #1f0757;
                    border-radius: 0.25rem;
                    opacity: .5;
                    border: none;
                    filter: invert(1) grayscale(100%) brightness(200%);
                }
            }

            .modal-body {
                label {
                    border: none;
                    background: inherit;
                }

                .conversion1 {
                    color: #fff;
                }

                .form-group {
                    .form-control {
                        color: #ffffff;
                        background-color: #242644;
                        outline: none;
                        border-color: rgba(255, 255, 255, 0.1);

                        &:focus {
                            background-color: #cfe2ff;
                            border-color: #cfe2ff;
                            color: #073984;
                        }
                    }

                    .input-group-text {
                        color: #ffffff;
                        background-color: #242644;
                        border-color: rgba(255, 255, 255, 0.1);
                    }
                }
            }

            .modal-footer {
                border-top-color: rgba(255, 255, 255, 0.1);

                .btn-primary {
                    color: #ffffff;
                    background-color: #0134d4;
                    border-color: #0134d4;

                    &:hover {
                        background-color: #025ce2;
                        border-color: #0257d5;
                    }
                }

                .btn-default {
                    color: #ffffff;
                    background-color: #ea4c62;
                    border-color: #ea4c62;

                    &:hover {
                        background-color: #c82333;
                        border-color: #bd2130;
                    }
                }
            }
        }
    }
}