.bv-plan-page {
    width: 100%;
    display: flex;
    flex-direction: row;

    .kr-manager-filter {
        display: flex;
        justify-content: space-between;
        padding: 15px;
        padding-bottom: 0px;

        .btn {
            font-weight: 600;
            color: #fff;
        }
    }

    div.kr-admin-table {
        box-sizing: border-box;
        width: 100%;
        padding: 15px;

        .plans {
            width: 100%;
            max-width: 836px;
            margin: 50px auto;
            overflow: hidden;

            .plan {
                float: left;
                width: 205px;
                margin: 20px 2px;
                padding: 15px 25px;
                text-align: center;
                background: white;
                background-clip: padding-box;
                border: 2px solid #e5ded6;
                border-color: green;
                border-radius: 5px;

                .plan-title {
                    margin-bottom: 12px;
                    font-size: 24px;
                    color: #36bce6;
                }

                .plan-price {
                    margin-bottom: 20px;
                    line-height: 1;
                    font-size: 28px;
                    font-weight: bold;
                    color: green;
                }

                .plan-features {
                    width: 120px;
                    margin: 20px auto 15px;
                    padding: 15px 0 0 15px;
                    border-top: 1px solid #e5ded6;
                    text-align: left;

                    .plan-feature {
                        line-height: 20px;
                        font-size: 15px;
                        color: #36bce6;
                        font-weight: bold;
                        margin: 10px auto 10px;
                    }
                }

                .plan-button {
                    width: 100%;
                    position: relative;
                    display: block;
                    line-height: 40px;
                    font-size: 16px;
                    font-weight: 500;
                    color: white;
                    text-align: center;
                    text-decoration: none;
                    background: green;
                    border-bottom: 2px solid #cf7e3b;
                    border-radius: 4px;
                }
            }
        }
    }
}

.bv-plan-mobile {
    display: none;
}

@media (max-width: 992px) {
    .bv-plan-page {
        display: none;
    }

    .bv-plan-mobile {
        display: block;
        min-height: 100%;
        overflow: hidden;
        overflow-y: auto;
        width: 100%;
        background-color: #0C153B;
        color: #8480ae;

        .card {
            background-color: #242644;
            border: 0 !important;
            border-radius: 0.5rem;
            display: flex;
            flex-direction: column;
            background-clip: border-box;
        }
    }
}