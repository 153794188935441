.bv-public-layout {
    height: 100vh;
    width: 100%;
    position: relative;

    img {
        height: auto;
        max-width: 100%;
    }

    .tradingview-widget-copyright {
        font-size: 13px !important;
        line-height: 32px !important;
        text-align: center !important;
        vertical-align: middle !important;
        /* @mixin sf-pro-display-font; */
        font-family: -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif !important;
        color: #9db2bd !important;

        .blue-text {
            color: #2962FF !important;
        }

        a {
            text-decoration: none !important;
            color: #9db2bd !important;

            &:visited {
                color: #9db2bd !important;

                .blue-text {
                    color: #2962FF !important;
                }
            }

            &:hover {
                .blue-text {
                    color: #1E53E5 !important;
                }
            }

            &:active {
                .blue-text {
                    color: #1848CC !important;
                }
            }
        }
    }

    .tradingview-widget-container {
        height: 46px;
        width: 100%;

        iframe {
            box-sizing: border-box;
            display: block;
            height: 46px;
            width: 100%;
        }
    }

    &__header {
        position: sticky;
        top: 0;
        width: 100%;
        z-index: 999;
        transition: all 0.4s ease-in-out;
        background-color: transparent;

        .desktop {
            display: block;
        }

        .mobile {
            display: none;
        }

        .navbar {
            padding: 0;
        }

        .navbar {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }

        .site-navbar {
            display: flex;
            position: relative;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            padding-top: 0;

            .menu-block-wrapper {
                -webkit-box-flex: 1;
                -ms-flex-positive: 1;
                flex-grow: 1;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
            }
                
            @media (min-width: 576px) {
                padding-top: 0;
            }
            @media (min-width: 992px) {
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                -ms-flex-flow: row nowrap;
                flex-flow: row nowrap;
                -webkit-box-pack: start;
                -ms-flex-pack: start;
                justify-content: flex-start;

                .menu-block-wrapper {
                    display: flex !important;
                    -ms-flex-preferred-size: auto;
                    flex-basis: auto;
                }
            }
        }

        .brand-logo {
            img {
                // width: 150px;
                height: 35px;
            }
        }

        .site-menu-main {
            .icon, i {
                margin-left: 15px;
                font-size: 15px;
                font-weight: 900;
                display: inline-block;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                line-height: 1;
            }

            .nav-item {
                display: inherit;
                font-weight: 600;
            }

            a {
                text-decoration: none;
                cursor: pointer;
            }

            .nav-item-has-children {
                a {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-pack: justify;
                    -ms-flex-pack: justify;
                    justify-content: space-between;
                }

                .icon {
                    transition: transform 0.4s ease-in-out;
                }

                &:hover {
                    .sub-menu {
                        opacity: 1;
                        visibility: visible;
                        z-index: 99;
                        pointer-events: visible;
                        border-radius: 0px 0px 15px 15px;
                        transform: translateY(0);
                        border-top: 3px solid #fd562a;
                    }

                    .icon {
                        transform: rotate(-180deg)
                    }
                }
            }

            .nav-link-item {
                font-size: 18px;
                font-weight: 500;
                font-style: normal;
                letter-spacing: normal;
                position: relative;
                cursor: pointer;
                text-align: center;
                color: #fff;
                transition: all 0.4s ease-in-out;

                &:hover {
                    color: #fd562a;
                }

                @media (min-width: 992px) {
                    padding-top: 35px !important;
                    padding-bottom: 33px !important;
                    padding-left: 20px !important;
                    padding-right: 20px !important;
                }

                @media (min-width: 992px) and (max-width: 1199px) {
                    padding-top: 35px !important;
                    padding-bottom: 33px !important;
                    padding-left: 12px !important;
                    padding-right: 12px !important;
                    font-size: 16px;
                }
            }

            li {
                list-style: none;
                .nav-item-has-children {
                    &:not(.has-megamenu) {
                        position: relative;
                    }
                }
            }

            .sub-menu {
                position: absolute;
                z-index: 500;
                background-image: linear-gradient(to left top, #fd562a, rgba(211, 40, 83, 0.99), rgba(148, 31, 95, 0.99), #4f2155, #161634);
                -webkit-box-shadow: -2px 2px 70px -25px rgba(0, 0, 0, 0.3);
                box-shadow: -2px 2px 70px -25px rgba(0, 0, 0, 0.3);
                padding: 20px 0px;
                -webkit-transition: opacity, transform 0.5s ease, top 0.5s ease, margin-top 0.5s ease, background-color 0.5s ease;
                -o-transition: opacity, transform 0.5s ease, top 0.5s ease, margin-top 0.5s ease, background-color 0.5s ease;
                transition: opacity, transform 0.5s ease, top 0.5s ease, margin-top 0.5s ease, background-color 0.5s ease;
                margin-top: 0;
                opacity: 0;
                transform: translateY(5%);
                visibility: hidden;

                @media (min-width: 992px) {
                    top: 80px;
                    min-width: 227px;
                    max-width: 227px;
                }

                .sub-menu--item {
                    color: #111;
                    font-size: 16px;
                    font-weight: 500;
                    padding-left: 30px;
                    padding-right: 30px;
                    padding-top: 6px;
                    padding-bottom: 6px;
                }
            }

            @media (min-width: 992px) {
                display: flex;
                margin-bottom: 0;
                padding-left: 0;

                a {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                }

                .sub-menu--item {
                    color: #000;

                    a {
                        transition: .4s;
                        color: #fff;

                        &:hover {
                            color: #fd562a;
                        }
                    }
                }
            }

            @media (min-width: 992px) and (max-width: 1199px) {
                // border-bottom: 1px solid rgba(255, 255, 255, 0.1);

                .nav-item {
                    font-size: 16px;
                }
            }
        }

        &--menu-right {
            .menu-block-wrapper {
                position: static;

                .menu-block {
                    margin-left: auto;
                }
            }
        }

        .sign-btn {
            text-transform: capitalize;
            background-color: transparent;
            border: none;
            color: rgba(255, 255, 255, 0.7);
            font-size: 18px;
            font-weight: 500;
            font-style: normal;
            letter-spacing: normal;
            font-family: "Work Sans", sans-serif;
            -webkit-transition-duration: 500ms;
            -o-transition-duration: 500ms;
            transition-duration: 500ms;

            img {
                max-width: 100%;
                height: auto;
                object-fit: contain;
            }

            @media (min-width: 992px) and (max-width: 1199px) {
                font-size: 16px;
                font-weight: 600;
            }
        }
    }

    &__header.opaque {
        background-color: #000;
    }

    &__feedback-button {
        height: 40px;
        border: solid 3px red;
        background: red;
        width: 100px;
        line-height: 32px;
        font-weight: 600;
        color: white;
        transform: rotate(-90deg);
        text-align: center;
        font-size: 17px;
        position: fixed;
        right: -40px;
        z-index: 999;
        cursor: pointer;
        text-decoration: none;
        top: 45%;
        font-family: "Roboto", helvetica, arial,
    }

    .bg-overlay {
        position: relative;
        z-index: 1;

        &::before {
            position: absolute;
            content: "";
            background-color: #060826;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            opacity: 0.92;
        }
    }

    .bg-fixed {
        background-attachment: fixed;
    }

    .bg-img {
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }

    .footer-contact-area {
        background-image: url(../../assets/images/bg-shape.jpg);
        padding: 100px 0;

        .mb-50 {
            margin-bottom: 50px;
        }

        .mt-30 {
            margin-top: 30px;
        }

        img {
            max-width: 100%;
            height: auto;
        }

        a {
            text-decoration: none;
        }

        p {
            font-size: 17px;
            color: rgba(255, 255, 255, 0.85);
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
            line-height: 1.7;
            letter-spacing: 0.5px;
        }

        .footer-logo {
            img {
                width: 160px;
            }
        }

        .f-add-info {
            a {
                color: rgba(255, 255, 255, 0.85);
            }
        }

        .footer-single-widget {
            h4 {
                font-size: 20px;
                margin-bottom: 20px;
                text-transform: capitalize;
                position: relative;
            }

            ul {
                margin: 0;
                padding: 0;

                li {
                    list-style: none;
            
                    a {
                        color: rgba(255, 255, 255, 0.80);
                        font-size: 15px;
                        margin-bottom: 5px;
                        display: block;
                        font-weight: 400;
                        -webkit-transition-duration: 400ms;
                        -o-transition-duration: 400ms;
                        transition-duration: 400ms;
            
                        &:hover {
                            color: #fd562a;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 992px) {
        .tradingview-widget-container {
            height: fit-content;
            min-height: 46px;
    
            iframe {
                height: 79px;
            }
        }

        &__header {
            // position: absolute;
            background-color: transparent;

            .desktop {
                display: none;
            }

            .mobile {
                display: flex;
                width: 100%;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding: 8px 16px;
                position: sticky;
                top: 0;

                .mobile-menu-trigger {
                    display: flex;
                    height: 30px;
                    width: 30px;
                    margin-left: 15px;
                    cursor: pointer;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;

                    span {
                        display: block;
                        height: 3px;
                        background-color: #fff;
                        width: 40px;
                        position: relative;

                        &::before {
                            top: -8px;
                        }

                        &::after {
                            top: 8px;
                        }

                        &::before, &::after {
                            content: '';
                            position: absolute;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background-color: #fff;
                        }
                    }
                }

                .nav-menu {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100vh;
                    width: 100%;
                    overflow: hidden;
                    opacity: 1;
                    background-image: linear-gradient(to left top, #fd562a, rgba(211, 40, 83, 0.99), rgba(148, 31, 95, 0.99), #4f2155, #161634);
                    transition: all 0.5s ease;

                    &-enter {
                        &-active {
                            animation: slideInLeft 0.5s forwards;
                        }
                    }

                    &-exit {
                        &-active {
                            animation: slideOutLeft 0.5s forwards;
                        }
                    }

                    &__head {
                        display: flex;
                        height: 60px;
                        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                        justify-content: space-between;
                        align-items: center;
                        position: relative;
                        background-image: linear-gradient(to left top, #fd562a, rgba(211, 40, 83, 0.99), rgba(148, 31, 95, 0.99), #4f2155, #161634);

                        & > .go-back, & > .title {
                            color: #fff;
                            font-size: 22px;
                        }

                        & > .go-back {
                            height: 50px;
                            width: 50px;
                            text-align: center;
                            line-height: 50px;
                        }

                        & > .close {
                            height: 50px;
                            width: 50px;
                            border-left: 1px solid rgba(0, 0, 0, 0.1);
                            cursor: pointer;
                            line-height: 50px;
                            text-align: center;
                            color: #fff;
                            font-size: 40px;
                        }
                    }

                    &__content {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                        overflow-y: auto;

                        & > ul {
                            margin: 0;
                            padding: 0;
                            z-index: 30;

                            & > li {
                                display: inherit;
                                margin: 0;
                                line-height: 1;
                                list-style: none;

                                .nav-link-item {
                                    text-decoration: none;
                                    color: #fff;
                                    font-size: 18px;
                                    font-weight: 600;
                                    font-style: normal;
                                    letter-spacing: normal;
                                    position: relative;
                                    text-align: center;
                                    line-height: 60px;
                                    height: 60px;
                                    padding: 0 50px 0 15px;
                                    display: block;
                                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                                }
                            }

                            & > li.has-children {
                                position: relative;

                                .icon {
                                    position: absolute;
                                    right: 12px;
                                    font-size: 14px;
                                    top: 50%;
                                    transform: translateY(-50%);
                                }
                            }
                        }

                        & > ul.extra {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            z-index: 50;
                            background-image: linear-gradient(to left top, #fd562a, rgba(211, 40, 83, 0.99), rgba(148, 31, 95, 0.99), #4f2155, #161634);

                            &-enter {
                                &-active {
                                    animation: slideInRight 0.5s forwards;
                                }
                            }

                            &-exit {
                                &-active {
                                    animation: slideOutRight 0.5s forwards;
                                }
                            }
                        }
                    }
                }
            }
        }

        .page {
            padding-top: 50px;
        }
    }
}